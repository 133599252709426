import React, { useCallback, useRef } from 'react'
import { Footer, Header, Layout, Nav } from '../components'
import { Contact } from '../containers'

const ContactPage = () => {
	const navRef = useRef<NavRef>(null)

	const onBurger = useCallback(() => {
		if (navRef.current) {
			navRef.current.show()
		}
	}, [navRef.current])

	return (
		<Layout secondary>
			<Contact />
		</Layout>
	)
}

export default ContactPage
